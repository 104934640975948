/*custom scroll */

.scrolling-div:hover::-webkit-scrollbar {
	width: 7px;
}

/* Track */

.scrolling-div:hover::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
}

/* Handle */

.scrolling-div:hover::-webkit-scrollbar-thumb {
	background: #E71D36;
	border-radius: 10px;
}

/* Handle on hover */

.scrolling-div:hover::-webkit-scrollbar-thumb:hover {
	background: transparent;
}

.scrolling-div::-webkit-scrollbar {
	width: 7px;
	background: transparent;
}

/* Track */

.scrolling-div::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 10px;
}

/* Handle */

.scrolling-div::-webkit-scrollbar-thumb {
	background: transparent;
	border-radius: 10px;
}

.autosuggest-suggestions-container-open {
	display: block;
	position: absolute;
	top: 51px;
	border: 1px solid #aaa;
	background-color: #fff;
	font-weight: 300;
	font-size: 16px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	z-index: 10;
	color: black;
}

.autosuggest-container {
	position: relative;
}

.autsuggest-suggestions-container {
	display: none;
}

.autosuggest-suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.autosuggest-suggestion {
	cursor: pointer;
	padding: 10px 20px;
}

.autosuggest-suggestion-highlighted {
	background-color: #ddd;
}

.fade-out {
	-webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
}

.emote-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 500px;
	margin: auto;
	height: fit-content;
	display: inline-block;
}

.emote-table {
	display: table;
	table-layout: fixed;
	border-spacing: 10;
	cursor: pointer;
	width: 100%;
}

.emote-cell-top {
	display: table-cell;
	text-align: center;
	vertical-align: top;
}

.emote-cell-bottom {
	display: table-cell;
	text-align: center;
	vertical-align: bottom;
}

.emote-top-edge {
	width: 30vw;
	height: 30vw;
	max-width: 100px;
	max-height: 100px;
	display: inline-block;
	vertical-align: top;
	margin-top: 30px;
}

.emote-top-middle {
	width: 30vw;
	height: 30vw;
	max-width: 100px;
	max-height: 100px;
	display: inline-block;
	vertical-align: top;
}

.emote-bottom-edge {
	width: 30vw;
	height: 30vw;
	max-width: 100px;
	max-height: 100px;
	display: inline-block;
	vertical-align: bottom;
	margin-bottom: 30px;
}

.emote-bottom-middle {
	width: 30vw;
	height: 30vw;
	max-width: 100px;
	max-height: 100px;
	display: inline-block;
	vertical-align: bottom;
}
