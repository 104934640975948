
.full-page-slide {
	min-width: 100%;
	min-height: calc(100vh - 40px);
}

.slide-nav {
	height: 40px;
	width: 100%;
	border-radius: 0 !important;
}

.slide-nav-buttons {
	border-radius: 0 !important;

}
